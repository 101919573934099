import React from "react";
import Navbar from "../components/Navbar";
import "../assets/css/form.css"
import Footer from "../components/Footer";

export default function Support() {
    return(
        <>
            <Navbar/>
            <div className="w3-content w3-padding-64 " style={{display:"flex", flexDirection:"column",
                justifyContent:"center"}}>
                <div>
                    Feel free to contact our team  at <a href={"tel:+91-9444083187"}>+91-9444083187</a> or send us an email
                    at <a href={"mailto:patanvenky@gmail.com"}>patanvenky@gmail.com</a>. Or fill out this submission form and we'll be in touch
                </div>
                    <form method="POST" name="contact" data-netlify="true" data-netlify-honeypot="bot-field"
                          style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                        <input type="hidden" name="form-name" value="contact"/>
                        <fieldset style={{ margin: "0 auto"}}>
                            <div>
                                <label htmlFor="contactName">Name</label>
                                <input type="text" id="contactName" name="contactName"/>
                            </div>
                            <div>
                                <label htmlFor="contactEmail">Email</label>
                                <input type="email"  id="contactEmail" name="contactEmail"/>
                            </div>
                            <div>
                                <label htmlFor="contactSubject">Subject</label>
                                <input type="text" id="contactSubject" name="contactSubject"/>
                            </div>
                            <div>
                                <label htmlFor="contactMessage">Message</label>
                            </div>
                            <div>
                                <textarea cols="20" rows="10" id="contactMessage" name="contactMessage"/>
                            </div>
                            <div>
                                <input type="submit" className="submit" value="Submit"/>
                            </div>
                        </fieldset>
                    </form>
                <div className="w3-padding-16" style={{margin:"auto"}}>
                    <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.074408426794!2d80.22995301527015!3d13.03093351709816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267aa41e2b297%3A0xd1ac28ac8b126d2f!2sPat%20%26%20Venky!5e0!3m2!1sen!2sus!4v1592806922119!5m2!1sen!2sus"
                    width="250" height="150" frameBorder="0" style={{border:0}} allowFullScreen="true" aria-hidden="false"
                    title="Pat & Venky's Location"/>
                </div>
            </div>
            <Footer/>
        </>
    );
}
